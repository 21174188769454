import { DefaultButton, Dropdown, PrimaryButton, ProgressIndicator } from '@fluentui/react';
import useLocationState from '../../hooks/useLocationState';
import { calcH } from '../../utils';

const SelectLawReviews = props => {
    const { state } = useLocationState();

    const onChange = (_, item) => {
        if(item)
            props.setTargets(item.selected ? [...props.targets, item.key] : props.targets.filter(key => key !== item.key))
    }

    return  <div className="select-law-reviews" style={{height: calcH(state)}}>
                Select Law Reviews
                <div className="submit-paper-row-group">
                    <Dropdown 
                        required
                        className="submit-paper-lr-item"
                        label="Target Journals"
                        options={props.lawReviews}
                        selectedKeys={props.targets}
                        multiSelect
                        onChange={onChange}
                    />
                </div>
                <div className="submit-paper-last-group">
                    <div>
                        <div className="submit-paper-button-group">
                            <div className="island">
                                <DefaultButton text="Back" onClick={props.setToggle} />
                            </div>
                            {props.targets.length > 0  && 
                                <div className="island">
                                    <PrimaryButton text="Submit" onClick={props.submit}/>
                                </div>
                            }
                        </div>
                        {props.progress > 0 && props.progress < 100 && <div className="island"><ProgressIndicator label="Uploading" percentComplete={props.progress}/></div>}
                        {
                            props.progress === 100 && 
                            <div>
                                <PrimaryButton text="Finsihed" onClick={props.clear} />
                                <div className="island">Upload Complete</div>
                            </div>
                        }
                    </div>
                </div> 
            </div>
}

export default SelectLawReviews;
import { memo, useEffect, useRef, useState, useMemo, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import lazy from "react-lazy-with-preload";
import pMinDelay from "p-min-delay";

import AdminRoute from "./Routes/AdminRoute";
import GroupAdminRoute from "./Routes/GroupAdminRoute";
import LawReviewRoute from "./Routes/LawReviewRoute";
import FreeRoute from "./Routes/FreeRoute";

import About from "./NavBar/About";
import Contact from "./NavBar/Contact";
import ConfirmDetails from "./Payment/ConfirmDetails";
import Demo from "./NavBar/Demo";
import DoReset from "./UserManagement/DoReset";
import EmailVerification from "./UserManagement/EmailVerification";
import Footer from "./Layout/Footer";
import Invest from "./Invest";
import NavBar from "./NavBar/NavBar";
import Orcid from "./Orcid";
import Search from './Search';
import PasswordReset from "./UserManagement/PasswordReset";
import ThirdParty from "./NavBar/ThirdParty";

import MessageBarPanel from "./Layout/MessageBarPanel";
import { ViewPortAnalyze as Analyze } from "./Analyze";
import Bugs from "./Layout/Bugs";
import DashboardAccounts from "./Dashboard/Accounts";
import DashboardGroups from "./Dashboard/Groups";
import DashboardMaintenance from "./Dashboard/Maintenance";
import Editors from "./SubmissionPortal/Editors";
import FacultyBios from "./FacultyBios/FacultyBios";
import HowTo from "./NavBar/HowTo";
import { LandingPage } from "./LandingPage";
import LawReviewPortal from "./SubmissionPortal/Portal";
import LawReviewUpload from "./SubmissionPortal/Upload";
import Loading from "./Layout/Loading";
import ManageUsers from "./Management/Users";
import ManagePapers from "./Management/Papers";
import ManageSubmit from "./Management/ManageSubmit";
import ManageSubmissions from "./Management/ManageSubmissions";
import Nodes from "./Nodes";
import Papers from "./Papers";
import Payment from "./Payment";
import RepositoryPaper from "./RepositoryPaper";
import Preprints from "./Preprints";
import Splash from "./Layout/Splash";
import SystemHealth from "./Dashboard/SystemHealth";
import Submit from "./Submit";
import Submissions from "./Submissions";
import Usage from "./Dashboard/Usage";
import useUser from "../hooks/useUser";
import useLocationState from "../hooks/useLocationState";
import { scholarSiftLightTheme, useWindowDimensions } from "ss-lib";
import { useTracking } from "react-tracking";
import StudentAmbassador from "./StudentAmbassador";
import "../_scss/App.scss";
import { FluentProvider, Toaster } from "@fluentui/react-components";
import { ssToast } from "../utils";
import { ProfileWrapper as Profile } from './Profile'

import FAQs from "./NavBar/FAQs";
import NotFound from "./Layout/NotFound";

const App = (props) => {
  const { user } = useUser();
  const { state, dispatch } = useLocationState();
  const { height } = useWindowDimensions();
  const h = useRef(0);
  const [minH, setMinH] = useState(0);
  const { Track } = useTracking({}, { dispatch: (data) => console.log(data) });

  useEffect(() => {
    if (!window.process) {
      window.process = {};
    } else {
      window.process = {
        ...window.process,
      };
    }
  });

  useEffect(() => {
    let x = Math.floor(
      Math.floor(state.viewport.h) -
        Math.ceil(state.viewport.f + state.viewport.m)
    );
    if (x !== minH) setMinH(x);
    return () => setMinH(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport.h, state.viewport.f, state.viewport.m]);

  useEffect(() => {
    if (height !== h.current) {
      h.current = height;
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "h",
        payload: Math.floor(h.current),
      });
    }
    return () => {
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "h",
        payload: 0,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  //Preload components
  const BrandSVG = useMemo(
    () => lazy(() => pMinDelay(import("./Layout/Logo/BrandSVG"), 2000)),
    []
  );
  const CRM = useMemo(
    () => lazy(() => pMinDelay(import("./Dashboard/CRM"), 2000)),
    []
  );

  const SignIn = useMemo(
    () => lazy(() => pMinDelay(import("./UserManagement/SignIn"), 2000)),
    []
  );

  BrandSVG.preload();
  CRM.preload();
  SignIn.preload();

  const header = useMemo(() => <NavBar user={user} />, [user]);

  return (
    <Suspense fallback={<Loading />}>
      <div id="portal-container"></div>
      <Router>
        <Track>
          <div
            className="app-wrapper"
            style={minH ? { minHeight: minH } : undefined}
          >
            {header}
            <MessageBarPanel />
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Loading />}>
                    <Splash
                      animation={
                        <Suspense fallback={<Loading />}>
                          <BrandSVG />
                        </Suspense>
                      }
                    />
                  </Suspense>
                }
              />
              <Route path="*" element={<NotFound />} />
              <Route
                path="/preprints"
                element={<FreeRoute children={<Preprints />} />}
              />
              <Route
                path="/analyze/:id?"
                element={<FreeRoute children={<Analyze />} />}
              />
             {
              /*
              <Route
                path="/analyze/:id"
                element={<FreeRoute children={<AnalyzeMS />} />}
              />
              */
             }
              <Route path="/faculty" element={<FacultyBios />} />
              <Route
                path="/bugs"
                element={<AdminRoute children={<Bugs />} />}
              />
              <Route
                path="/dashboard/accounts"
                element={<AdminRoute children={<DashboardAccounts />} />}
              />
              <Route
                path="/dashboard/crm"
                element={
                  <AdminRoute
                    children={
                      <Suspense fallback={<Loading msg="Loading dashboard" />}>
                        <CRM />
                      </Suspense>
                    }
                  />
                }
              />
              <Route
                path="/dashboard/groups"
                element={<AdminRoute children={<DashboardGroups />} />}
              />
              <Route
                path="/dashboard/maintenance"
                element={
                  <AdminRoute
                    children={
                      <DashboardMaintenance
                        profile={
                          user.current && user.current.scholarsift
                            ? user.current.scholarsift.profile
                            : undefined
                        }
                        email={
                          user.current && user.current.scholarsift
                            ? user.current.scholarsift.email
                            : undefined
                        }
                      />
                    }
                  />
                }
              />
              <Route path="/tutorials" element={<HowTo />} />
              <Route
                path="/dashboard/usage"
                element={
                  <AdminRoute
                    children={
                      <Suspense fallback={<Loading msg="testing" />}>
                        <Usage />
                      </Suspense>
                    }
                  />
                }
              />
              <Route path="/demo" element={<Demo />} />
              <Route
                path="/manage/users"
                element={<GroupAdminRoute children={<ManageUsers />} />}
              />
              <Route
                path="/manage/papers"
                element={<GroupAdminRoute children={<ManagePapers />} />}
              />
              <Route
                path="/manage/submit"
                element={<GroupAdminRoute children={<ManageSubmit />} />}
              />
              <Route
                path="/manage/submissions"
                element={<GroupAdminRoute children={<ManageSubmissions />} />}
              />
              <Route
                path="/nodes"
                element={<AdminRoute children={<Nodes />} />}
              />
              <Route
                exact
                path="/papers"
                element={<FreeRoute children={<Papers />} />}
              />
              <Route
                path="/papers/:id"
                element={<RepositoryPaper />}
              />
              <Route
                exact
                path="/preprints"
                element={<FreeRoute children={<Papers />} />}
              />
              <Route path="/subscribe" element={<Payment />} />
              <Route path="/subscribe/checkout" element={<ConfirmDetails />} />
              <Route
                path="/profile"
                element={
                  <FreeRoute
                    children={
                      <Suspense fallback={<Loading />}>
                        <Profile />
                      </Suspense>
                    }
                  />
                }
              />
              <Route
                path="/portal/editors"
                element={<LawReviewRoute children={<Editors />} />}
              />
              <Route
                path="/portal/list"
                element={<LawReviewRoute children={<LawReviewPortal />} />}
              />
              <Route
                path="/portal/upload"
                element={<LawReviewRoute children={<LawReviewUpload />} />}
              />
              <Route
                path="/welcome"
                element={<LandingPage />}
              />
              <Route
                path="/todo"
                element={
                  <LawReviewRoute
                    children={
                      <LawReviewPortal
                        profile={user.current.scholarsift.profile}
                      />
                    }
                  />
                }
              />
              <Route
                exact
                path="/search"
                element={<FreeRoute children={<Search />} />}
              />
              <Route
                path="/submit/*"
                element={<FreeRoute children={<Submit />} />}
              />
              <Route
                path="/submissions"
                element={<FreeRoute children={<Submissions />} />}
              />
              <Route path="/about" element={<About direct={true} />} />
              <Route path="/contact" element={<Contact direct={true} />} />
              <Route path="/faqs" element={<FAQs direct={true} />} />
              <Route
                path="/cookies"
                element={
                  <Suspense fallback={<Loading />}>
                    <Splash
                      animation={
                        <Suspense fallback={<Loading />}>
                          <BrandSVG />
                        </Suspense>
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/terms"
                element={
                  <Suspense fallback={<Loading />}>
                    <Splash
                      animation={
                        <Suspense fallback={<Loading />}>
                          <BrandSVG />
                        </Suspense>
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/privacy"
                element={
                  <Suspense fallback={<Loading />}>
                    <Splash
                      animation={
                        <Suspense fallback={<Loading />}>
                          <BrandSVG />
                        </Suspense>
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/security"
                element={
                  <Suspense fallback={<Loading />}>
                    <Splash
                      animation={
                        <Suspense fallback={<Loading />}>
                          <BrandSVG />
                        </Suspense>
                      }
                    />
                  </Suspense>
                }
              />
              <Route path="/doreset/:id" element={<DoReset />} />
              <Route
                path="/emailverification/:id"
                element={<EmailVerification />}
              />
              <Route path="/invest" element={<Invest />} />
              <Route path="/licenses" element={<ThirdParty />} />
              <Route path="/orcid" element={<Orcid />} />
              <Route path="/resetpassword" element={<PasswordReset />} />
              <Route
                path="/signin/"
                element={
                  <Suspense fallback={<Loading />}>
                    <SignIn />
                  </Suspense>
                }
              />
              <Route
                path="/signin/:message"
                element={
                  <Suspense fallback={<Loading />}>
                    <SignIn />
                  </Suspense>
                }
              />
              <Route
                path="/dashboard/system&health"
                element={<SystemHealth />}
              />
              <Route
                path="/studentambassador"
                element={<StudentAmbassador />}
              />
            </Routes>

            <Footer nav={<Navigate to="/licenses" />} />
          </div>
        </Track>
      </Router>
      <FluentProvider theme={scholarSiftLightTheme}><Toaster toasterId={ssToast} position="top" /></FluentProvider>
    </Suspense>
  );
};

export default App;

import { useEffect } from 'react';
import SubmitComponent from './SubmitComponent';
import ViewPort from '../Layout/ViewPort';
import useLocationState from '../../hooks/useLocationState';
import { Debug } from 'ss-lib';
import useUser
 from '../../hooks/useUser';
const TopMargin = 100;

const Submit = props => {
    const { state } = useLocationState();
    const { setTitle } = useUser();

    useEffect(() => {
        document.title = "ScholarSift - Submit a Paper";
        setTitle("Submit a Paper");
    })
    
    if(!Debug())
        return <div
                    className="submission-fill"
                    style={{
                    height: state.viewport.h - (state.viewport.f + state.viewport.m),
                    }}
                >
                    <div
                    className="loading"
                    style={
                        !props.management
                        ? {
                            height:
                                state.viewport.h - (state.viewport.f + state.viewport.m),
                            }
                        : undefined
                    }
                    >
                    We're signing up Law Reviews now, and are excited to show you this
                    feature in the near future.
                    </div>
                </div>

    if(props.unwatched === undefined)
        return <ViewPort><div style={{ minHeight: state.viewport.h - (state.viewport.m + state.viewport.f + TopMargin)}} ><SubmitComponent {...props} TopMargin={TopMargin}/></div></ViewPort>
    return <SubmitComponent {...props}/>
}

export default Submit;
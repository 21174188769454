import { useEffect } from "react";
import useUser from "../../hooks/useUser";
import ViewPort from "../Layout/ViewPort";
import SubmissionPanel from "./SubmissionPanel";

const Submissions = (props) => {
  const { setTitle } = useUser();

  useEffect(() => {
    document.title = "ScholarSift - Manage Submissions";
    setTitle("Manage Submissions");
    return () => {
      setTitle("");
    };
  });

  if (window.location.host.indexOf("scholarsift") === -1) console.log(props);

  return (
    <ViewPort>
      <SubmissionPanel {...props} />
    </ViewPort>
  );
};

export default Submissions;

import { useState, useRef } from "react";
import { Checkbox, TextField } from "@fluentui/react";

const AuthorField = (props) => {
  const [contact, setContact] = useState(props.contact);
  const [name, setName] = useState(props.name ? props.name : "");
  const [email, setEmail] = useState(props.email ? props.email : "");

  const testEmail = () => {
    let a = email.indexOf("@");
    if (email.indexOf(".", a + 1) > -1) return "";
    return "Email address required";
  };

  const ref = useRef();
  const updateAuthor = (key, value) => {
    let u = { name: name, email: email, contact: contact };
    props.setAuthors(key ? { ...u, [key]: value } : u);
  };

  const handleChange = (_, isChecked) => {
    setContact(!isChecked);

    return () => updateAuthor();
  };

  return (
    <>
      <div className="author-item">
        <TextField
          className="author-item-field"
          placeholder={name ? "" : "Additional Author Name"}
          value={name}
          defaultChecked={props.contact}
          onChange={(_, newValue) => {
            setName(newValue);
          }}
          onBlur={updateAuthor}
        />
        <TextField
          required={name.length > 0}
          className="author-item-field"
          placeholder={email ? "" : "Additional Author Email"}
          value={email}
          onGetErrorMessage={(v) => (name.length === 0 ? "" : testEmail())}
          onChange={(_ev, newValue) => {
            setEmail(newValue);
          }}
          onBlur={updateAuthor}
        />
      </div>
      <Checkbox
        className="author-item-field"
        label="Update author when submission status changes"
        defaultChecked={props.contact}
        ref={ref}
        onChange={handleChange}
      />
    </>
  );
};

export default AuthorField;

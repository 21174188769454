import { useState, useEffect } from 'react';
import { CheckboxVisibility, DetailsList, Icon, PrimaryButton, TextField, TooltipHost } from '@fluentui/react';
import Authors from '../../Submit/Authors';
import useUser from '../../../hooks/useUser';
import { useWindowDimensions } from 'ss-lib';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import axios from 'axios';

const onRenderColumn = (item, column, index, updateItem, removeItem) => {
    switch(column.key)
    {
        case 'name':
            return <div className="portal-list-item"><div className="portal-list-item-element">{item[column.key]}</div><TooltipHost content={"Remove " + item[column.key]}><Icon className="file-list-close-icon" iconName="Clear" onClick={() => removeItem(item)}/></TooltipHost></div>;
        case 'title':
            return <TextField value={item.title} onChange={(_, newValue) => { updateItem({...item, title: newValue}, item.index) }} />
        case 'authors':
            return <Authors authors={item.authors} hideHeader={true} setAuthors={v => {updateItem({...item, authors: v}, item.index)}} />
        default:
            return null;
    }
}

const getGoogleItems = (item, ret) => {
    if(!item.results.docs)
        return ;
    item.results.docs.filter(v => !ret.has(v.name)).forEach(v => {
        v.accessToken = item.token;
        ret.set(v.name, v);
    })
}

const getMicrosoftItems = (item, ret) => {
    if(!item.results)
        return;
    console.log(item)
    console.log(ret)
    item.results.value.filter(v => !ret.has(v.name)).forEach(v => {
        v.accessToken = item.results.accessToken;
        v.apiEndpoint = item.results.apiEndpoint;
        ret.set(v.name, v);
    });
}

const getDropboxItems = (item, ret) => {
    if(!item.results)
        return ;
    item.results.filter(v => !ret.has(v.name)).forEach(v => { ret.set(v.name, v); })
}

const getItems = items => {
    let ret = new Map();
    items.forEach(v => {
        if(v.type === "google") 
            getGoogleItems(v, ret);
        else if(v.type === "microsoft")
            getMicrosoftItems(v, ret);
        else if(v.type === "dropbox")
            getDropboxItems(v, ret);
    });
    return [...ret.values()];
}

const SharedList = props => {
    const { user, saveUser } = useUser();
    const [items, setItems] = useState(getItems(props.items ? props.items : []));
    const dim = useWindowDimensions();

    useEffect(() => {
        setItems(getItems(props.items ? props.items : []));
    }, [props.items])

    const updateItem = (item, index) => {
        let itms = [...items];
        itms[index] = item;
        setItems(itms);
    }

    const Submit = retries => {
        if(retries && retries > 5)
            return ;

        axios.post("/api/sharedsubmit", {items: items, targets: [user.current.scholarsift.profile]}, { 
            headers : {
                        "Authorization":"Bearer " + user.current.token.id,
                        "Coda" :  user.current.scholarsift.coda
                    }
            })
            .then(results => {
                props.setSubmitted(items.filter(v => v.name));
            })
            .catch(error =>  { APIErrorHandler(error, user, saveUser, () => { Submit(retries ? retries + 1 : 1) }) });
    }

    if(items.length === 0)
        return null;

    return  <div className="file-list">
                <div className="file-list-wrapper">
                    <div>All Fields Are Optional</div>
                    <div className="file-list-close"><Icon className="file-list-close-icon" iconName="Clear" onClick={props.Clear}/></div>
                </div>
                <DetailsList
                    checkboxVisibility={CheckboxVisibility.hidden} 
                    items={items}
                    columns={[{key: "name", name: "File Name", minWidth: dim.width/10, maxWidth: dim.width/10}, {key: "title", name: "Title", minWidth: dim.width/4, maxWidth: dim.width/4}, {key: "authors", name:"Author(s)"}]}
                    onRenderItemColumn={(item, index, column) => onRenderColumn(item, column, index, updateItem, item => { setItems(items.filter(v => v.index !== item.index)) }) } 
                />
                <div className="island"><PrimaryButton text="Submit" className="submit-button" onClick={Submit}/></div>
            </div>
}

export default SharedList;
import { APIErrorHandler, refreshGoogle } from '../actions/APIErrorHandler';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { Debug } from '../utils';
import creds from '../creds.json';
import { pca }from '../context/UserContext';

export const GetUserList = (user, saveUser, callBack, setError) => {
	console.log("Getting User List")
	if(user.current.token.type === "Microsoft")
		microsoftUserList(user, saveUser, callBack, setError);
	else if(user.current.token.type === "Google")
		googleUserList(user, saveUser, callBack, setError);
}

const googleUserList = (user, saveUser, callBack, setError) => {
	console.log("GOOGLE USER LIST")
	let domain = user.current.scholarsift.email.substring(user.current.scholarsift.email.indexOf("@") + 1).trim();
	axios.get("https://www.googleapis.com/admin/directory/v1/users?domain=" + domain + "&key=AIzaSyAAZ6jM7ees0CtXdf0IkCFhcxuJx67KjRw", {
					headers : {
						"Authorization":"Bearer " + (user.current.token.access && user.current.token.access.access_token ? user.current.token.access.access_token : user.current.token.id),
					}
		})
		.then(response => {
			console.log(response.data)
			let users = {};
			response.data.users.forEach( v => {
				var primary = "";
				v.emails.forEach( vv => {
					if(vv.primary)
						primary = vv.address;
				})

				users[primary] = {
					first: v.name.givenName,
					last: v.name.familyName,
					email: primary,
					company: domain,
					ukey: uuid()
				};
			})
			
			GetActiveUsers(user, users, callBack);
		})
		.catch(error => {
			console.log(error)
			if(error.response.status === 401) {
				if(!window.google)
				{
					console.log("Load Hooli");
					return ;
				}

				const client = window.google.accounts.oauth2.initTokenClient({
						client_id: creds.googleClientId[window.location.hostname],
						callback: (res) => { 
							let u = {...user.current};
							u.token.access = res;
							saveUser(u);
							googleUserList(user, saveUser, callBack,  setError);
						},
						scope: 'https://www.googleapis.com/auth/admin.directory.user.readonly',
					});
				client.requestAccessToken();
				return ;
			}
			APIErrorHandler(error, user, saveUser, v => { 
				if(v === "login") {
					if(setError)
						setError("login");
					return;
				}
				googleUserList(user, saveUser, callBack,  setError);
			})
		});
}

const microsoftUserList = (user, saveUser, callBack, setError) => {
	const tokenRequest = {
        scopes: ['user.read','user.readbasic.all'],
		account: user.account
	};

	if(window.location.host.indexOf("scholarsift") === -1)
		console.log(tokenRequest)

	user.instance.acquireTokenSilent(tokenRequest)
							.then(res => {
								axios.get("https://graph.microsoft.com/v1.0/users?$select=givenName,surname,mail,companyName,userPrincipalName", {
									headers : {
									"Authorization":"Bearer " + res.accessToken
									}
								})
								.then(res => {
									let users = {};
									res.data.value.forEach((v) => {
										users[v.userPrincipalName] = {
											first: v.givenName,
											last: v.surname,
											email: v.userPrincipalName,
											company: v.companyName,
											ukey:uuid()
										}
									});
							
									GetActiveUsers(user, users, callBack);
								})
								.catch(error => {
									APIErrorHandler(error, user, saveUser, v => { 
										if(v === "login") {
											if(setError)
												setError("login");
											return;
										}
										microsoftUserList(user, saveUser, callBack, setError);
									})
								});
							})
							.catch(err => {console.log("Request: "); console.log(tokenRequest);console.log(err); if(callBack)callBack([])})
}

export const GetActiveUsers = (user, users, callBack) => {
	axios.get("/api/active_users", {
				headers : {
					"Authorization":"Bearer " + user.current.token.id,
					"Coda" : user.current.scholarsift.coda
				}
		})
		.then(response => {
			let usrs = [];
			if(response.data) {
				let keys = Object.keys(users);
				response.data.users.forEach(v => {
					if(keys.indexOf(v.email) > -1) {
						let n = {...users[v.email]}
						if(!n.profile)
							n.profile = v.profile;
						if(n.profile !== v.profile)
							n.others = n.others ? [...n.others, v.profile] : [v.profile];
						if(v.affiliation) {
							let j = JSON.parse(v.affiliation);
							if(j.limits && j.limits.length)
								n.limits = [...j.limits];
						}
						users[v.email] = n;
					}
				})

				keys.forEach(k => {
						if(users[k].first && users[k].last)
						{
							let n = {...users[k], text: users[k].email.toLowerCase() + " " + users[k].first.toLowerCase() + " " + users[k].last.toLowerCase()};
							usrs.push(n);
						}	
				})
			}
			console.log(usrs)
			callBack(usrs);
		})
		// No Catch so it gets caught by parent (hopefully...)
}

export const doCreateUser = (user, saveUser, location, state, dispatch, notify, retries) => {
	if(retries && retries > 5)
		return ;
	
	axios
		.post("/api/mcu", {user: state[location.pathname].current, notify: notify}, {
			headers: {
				"Authorization":"Bearer " + user.current.token.id,
				"Coda" :  user.current.scholarsift.coda
			}
		})
		.then(res => {
			console.log(res);
			dispatch({type:'path-key-update', path: location.pathname, key: "current", payload: {...state[location.pathname].current, profile: res.data.profile}});
		})
		.catch(err => { APIErrorHandler(err, user, saveUser, () => doCreateUser(user, saveUser, location, state, dispatch, notify, retries + 1)) } )
}
import { Label } from "@fluentui/react";

const SelectButtonPanel = props => {
    return (
        <div
            className="select-dnd"
            onDragEnter={e => e.preventDefault()} 
            onDragOver={e => e.preventDefault()} 
            onDrop={e => {
                    e.preventDefault();
                    var f = e.dataTransfer.files[0];
                    if(f) 
                        props.processFile(props.target, f);
                }}
                aria-label="analyze drop area"
    >
            <div className="submit-btn" style={{ boxShadow: "0 3px 10px 0 rgba(59,64,66,0.5)" }} onClick={() => { props.selectFile(props.target) }}>{props.select}</div>
            <Label style={{textAlign: "center"}}>{props.label}</Label>
        </div>)
}

export default SelectButtonPanel;
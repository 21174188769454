import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useLocationState from '../../../hooks/useLocationState';
import SelectionPanel from './SelectionPanel';
import OkCancel from '../../Layout/OkCancel';
import TextInput from '../../Layout/TextInput';
import TextInputArea from '../../Layout/TextInputArea';

const getFrom = (item, lawreviews) => {
    let r = item.status.filter(v => v.status === 2);
    return r.length ? lawreviews[r[0].pub] : "";
}

const getDeadline = item => {
    let r = item.status.filter(v => v.status === 2);
    return r.length ? r[0].deadline : "YYYY-MM-DD";
}

const EXPEDITE = 4;

const Expedite = props => {
    const location = useLocation();
    const { state, dispatch } = useLocationState();
    const [from, setFrom] = useState(getFrom(props.item, props.lawreviews));
    const [deadline, setDeadline] = useState(getDeadline(props.item));
    const [notes, setNotes] = useState("");

    useEffect(() => {
        if(state[location.pathname].items === undefined) {
            let items = props.item.status.filter(v => v.status < 2).map(v => ({name: props.lawreviews[v.pub], id: v.pub}));
            items.sort((a, b) => a.name.localeCompare(b.name) );
            dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: items});
        }
    })

    const onOK = () => {
        let exp = state[location.pathname].items.filter(v => v.checked);
        let items = state[location.pathname].items.filter(v => !v.checked);
        dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: items});
        props.doUpdate(state[location.pathname].items.filter(v => v.checked).map(v => v.name), EXPEDITE, notes);
        props.onOK(exp.length && items.length ? 3 : 0);
    }

    return  <div>
                <div className="expedite-title-panel">
                    <div className="expedite-title">Expedite</div>
                    <div className="expedite-sub-title">{props.item.title}</div>
                </div>
                <div className="expedite-panel">
                    <div className="expedite-from">
                        <TextInput
                            label="Offeror"
                            placeholder="Offeror"
                            required={true}
                            value={from}
                            onChange={(e) => { setFrom(e.target.value) }}
                        />
                        <TextInput
                            label="Expiration"
                            placeholder="Expiration"
                            required={true}
                            value={deadline}
                            onChange={(e) => { setDeadline(e.target.value) }}
                        />
                        <TextInputArea 
                            label="Notes"
                            placeholder="Notes"
                            value={notes}
                            onChange={(e) => { setNotes(e.target.value) }}
                        />
                    </div>
                    <div className="expedite-to">
                        <div className="expedite-title-panel">
                            <div className="expedite-sub-title">Requests</div>
                        </div>
                        <SelectionPanel />
                    </div>
                    <OkCancel ok="Expedite" onOK={onOK} onCancel={props.onCancel} />
                </div>
            </div>
}

export default Expedite;
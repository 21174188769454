import { Label } from '@fluentui/react';
import AuthorField from './AuthorField';
import { v4 as uuid} from "uuid";

const getAuthorFields = props => {
    console.log(props);
    let ret = [];
    let arr = [];
    if(props.authors) {
        if(Array.isArray(props.authors))
            arr = [...props.authors];
        else
            arr = JSON.parse(props.authors);
    }
    ret.push(<AuthorField key={uuid()} setAuthors={v => {props.setAuthors([...arr, v])}}/>);

    if(arr.length === 0)
        return ret;

    arr.forEach((v, i) => {
        if(!((!v.email || v.email.length === 0) && (!v.name || v.name.length === 0)))
            ret.splice(i, 0, <AuthorField key={uuid()} name={v.name} email={v.email} contact={v.contact} setAuthors={vv => { arr[i] = vv; props.setAuthors(arr) }}/>);
    })
    return ret;
}

const Authors = props => {
    const authorFields = getAuthorFields(props);

    return  <div className="author-panel">
                {!props.hideHeader && <Label>Author(s)</Label>}
                {authorFields}
            </div>
}

export default Authors;
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import ViewPort from "../Layout/ViewPort";
import useLocationState from "../../hooks/useLocationState";
import { Debug, Papers, useProfile } from "ss-lib";
import useUser from '../../hooks/useUser';
import { AddItem, CheckFile, EditPaper, ListPapers, RemoveItem, SearchPapers, UpdateItem, UpdatePaperStats } from './actions';
import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import { ssToast } from "../../utils";

const bottomMargin = 25;

export const PaperPage = props => {
    const p = useProfile();
    const [ref, {width}] = useMeasure();
    const location = useLocation();
    const { user, saveUser, setTitle } = useUser();
    const [newItem, setNewItem] = useState();
    const [incomplete, setIncomplete] = useState(location.pathname.indexOf("dashboard") > -1 ? [] : props.incomplete ? props.incomplete : user.current && user.current.scholarsift && user.current.scholarsift.incomplete ? user.current.scholarsift.incomplete : []);
    const [items, setItems] = useState(props.items ? props.items : location.state && location.state.items ? location.state.items : undefined);
    const [stats, setStats] = useState(props.stats ? props.stats : user.current.scholarsift.pinfo ? user.current.scholarsift.pinfo.stats : []);
    const [searchMode, setSearchMode] = useState(false);
    const [searchItems, setSearchItems] = useState([]);
    const [processing, setProcessing] = useState();
    const [fetching, setFetching] = useState(false);
    const [focus, setFocus] = useState(location.state && location.state.id ? {pathname: location.pathname, id: location.state.id} : undefined);
    const { dispatchToast } = useToastController(ssToast);

    useEffect(() => {
        if(!props.account && location.pathname.indexOf("welcome") === -1) {
            setTitle(location.pathname.indexOf("preprint") === -1 ? "Papers" : "Preprints")
            window.document.title = location.pathname.indexOf("preprint") === -1 ? "ScholarSift - Papers" : "ScholarSift - Preprints";
        }
    })

    useEffect(() => {
        if(Debug()) {
            console.log("LIST/ANALYZE")
            console.log(props)
            console.log(p)
            console.log(location)
            console.log(fetching)
        }
        let pro = props.account ? props.account : p.pid;
        let nm = props.name ? props.name : p.name;
        
        if(props.items)
            setItems(props.items);
        else if(location.state && location.state.items)
            setItems(location.state.items);
        else if(!fetching && items === undefined)
        {
            setFetching(true);
            ListPapers(user, saveUser, p.pid !== pro ? {profile: pro, name: nm} : {}, (data) => { if(Debug()) console.log(data); setIncomplete(data.incomplete); setItems(location.pathname.indexOf("preprint") > -1 ? data.items.filter(v => v.pubStatus === "Preprint" || v.pubStatus === "Forthcoming") : data.items);}, setFetching);
        }

        if(location.state && location.state.file) {
            analyzeFile(location.state.file);
        }

        if(props.stats || (user.current && user.current.scholarsift && user.current.scholarsift.pinfo))
            setStats(props.stats ? props.stats : user.current.scholarsift.pinfo.stats);

        if(location.pathname.indexOf("dashboard") === -1)
            setIncomplete(props.incomplete ? props.incomplete : user.current && user.current.scholarsift && user.current.scholarsift.incomplete ? user.current.scholarsift.incomplete : []);

        if(location.state && location.state.id && (!focus || focus.pathname !== location.pathname || focus.id !== location.state.id)) 
            setFocus({pathname: location.pathname, id: location.state.id})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, p, location, user.current])

    const onDrop = e => {
        if(props.items)
            return ;
        var f = e.dataTransfer.files[0];
        if (f === undefined)
            return;
        let name = f.name.toLowerCase();
        if (
            !name.endsWith(".pdf") &&
            !name.endsWith(".docx") &&
            !name.endsWith(".doc") &&
            !name.endsWith(".txt")
        )
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Invalid file type</ToastTitle><ToastBody>Only .doc, .docx, .pdf, and .txt are allowed</ToastBody></Toast>, {intent: "warning"});
        else 
            analyzeFile(f);
    }

    const SelectFile = e => {
        analyzeFile(e.target.files[0])
    }

    const analyzeFile = f => {
        setProcessing("Checking File");
        CheckFile(user, saveUser, f, (v) => { setProcessing(undefined); setNewItem(v) }, (v) => { console.log(v); dispatchToast(<Toast appearance='inverted'><ToastTitle>Error Checking File</ToastTitle><ToastBody>{v}</ToastBody></Toast>, {intent:"error"}) });
    }

    const saveItem = item => {
        EditPaper(user, saveUser, item, 
            () => { 
                if(items.filter(v => v.n === item.n).length === 0)
                    setItems([...items, item]); 
                else
                    setItems(items.map(v => v.n === item.n ? item : v));
                setNewItem(item);
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Success</ToastTitle><ToastBody>Saved {item.title}</ToastBody></Toast>, {intent: "success"}) 
            },
            (err) => { console.log(err); dispatchToast(<Toast appearance='inverted'><ToastTitle>Error</ToastTitle><ToastBody>Could not update {item.title}{err.indexOf("version") > -1 ? <p>{err}</p> : undefined}</ToastBody></Toast>, {intent: "error"}) }
        );
    }

    const search = text => {
        if(text === undefined) 
            setSearchMode(false);
        else {
            setProcessing("Searching");
            SearchPapers(user, useUser, text, (items) => { setProcessing(undefined); setSearchItems(items); setSearchMode(true)}, (v) => { console.log(v); dispatchToast(<Toast appearance='inverted'><ToastTitle>Search Error</ToastTitle><ToastBody>{v}</ToastBody></Toast>, {intent:"error"}) })
        }
    }

    const addItem = item => {
        AddItem(user, saveUser, {profile: props.account, n:item.n}, () => { setItems([...items, item]); setNewItem(item) }, (v) => { console.log(v); dispatchToast(<Toast appearance='inverted'><ToastTitle>Add Item Error</ToastTitle><ToastBody>{v}</ToastBody></Toast>, {intent:"error"}) })
    }

    const removeItem = item => {
        RemoveItem(user, saveUser, {profile: props.account, n: item.n}, () => { setItems(items.filter(v => v.n !== item.n)); setNewItem(undefined); }, (v) => { console.log(v); dispatchToast(<Toast appearance='inverted'><ToastTitle>Remove Item Error</ToastTitle><ToastBody>{v}</ToastBody></Toast>, {intent:"error"}) })
    }

    const updateFile = data => {
        UpdateItem(user, saveUser, data,
            () => {
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Success</ToastTitle><ToastBody>Updated {data.item.title}</ToastBody></Toast>, {intent: "success"}) 
            },
            (err) => { 
                console.log(err); 
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Error</ToastTitle><ToastBody>Could not update {data.item.title}</ToastBody></Toast>, {intent: "error"}) 
            }
        )
    }

    const setSelected = item => {
        console.log(item)
        if(item) {
            let itms = items ? items.filter(v => v.n === item.n) : [];
            if(itms.length) {
                let stat = stats ? [...stats.filter(v => v.n !== itms[0].n), {...itms[0].stats, n: item.n}] : [{...itms[0].stats, n: item.n}];
                saveUser({...user.current, scholarsift: {...user.current.scholarsift, pinfo: {...user.current.scholarsift.pinfo, stats: stat}}})
            }
        }
        else 
            setFocus(undefined);
        setNewItem(item);
    }

    if(Debug()) {
        console.log("PAPERS:")
        console.log(props)
        console.log(location)
        console.log(newItem);
        console.log(items)
        console.log(focus)
        console.log(stats)
    }
    
    return  <div ref={ref}>
                <Papers 
                    action="upload"
                    focus={focus ? focus.id : undefined}
                    removeItem={removeItem} 
                    incomplete={incomplete}
                    location={location.pathname} 
                    items={searchMode ? searchItems : items === undefined ? [] : items} 
                    fetching={fetching} 
                    processing={processing} 
                    onDrop={onDrop} 
                    SelectFile={SelectFile} 
                    selected={newItem} 
                    saveItem={saveItem} 
                    doSearch={search}
                    stats={stats}
                    searchMode={searchMode} 
                    UpdateFile={updateFile}
                    addItem={addItem}
                    preview={props.preview}
                    setSelectedItem={setSelected}
                />
            </div>
            
}

const PaperWrapper = props => {
    const { state } = useLocationState();
    return  <ViewPort>
                <div style={{marginBottom: bottomMargin, minHeight: props.height ? props.height : state.viewport.h ? (state.viewport.h - (state.viewport.m + state.viewport.f + bottomMargin)) : "85vh"}}>
                    <PaperPage {...props} />
                </div>
            </ViewPort>
}

export default PaperWrapper;
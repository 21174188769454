import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, ComboBox, TooltipHost } from '@fluentui/react';
import Status from './Status';
import SubmittedItem from './SubmittedItem';
import useUser from '../../hooks/useUser';
import { useWindowDimensions } from 'ss-lib';
import axios from 'axios';
import { APIErrorHandler } from '../../actions/APIErrorHandler';
import { mTypes } from '../../utils';
import { v4 as uuid} from "uuid";
import ScrollingFilterableList from '../ScrollingFilterableList';
import Actions from './Actions';

const SubmissionList = props => {
    const dim = useWindowDimensions();
    const { user, saveUser } = useUser();
    const [columns, setColumns] = useState();
    const [items, setItems] = useState([]);
    const [mOps, setMops] = useState([...mTypes]);
    const  navigate = useNavigate();
    
    useEffect(() => {
        //Prep Items
        let itms = [];
        if(props.items) {
            props.items.forEach(v => {
                let itm = {...v};
                itm.topics = "";
                if(itm.stats) {
                    var stats = JSON.parse(itm.stats);
                    Object.keys(stats).filter(k => Array.isArray(stats[k])).sort((a, b) => stats[b].length - stats[a].length).forEach((k, i) => { 
                        //This can be updated to include subtypes and whatnot
                        if(i < 5 && stats[k].length > 4)
                            itm.topics += k + " (" + stats[k].length + ")\n";
                    })
                }
                itm.text = JSON.stringify(v).toLowerCase();
                itms.push(itm);
            })
        }

        //Set Columns
        let cols = [];
        cols.push({
            key: 'title',
            name: 'Title',
            minWidth: 400,
            maxWidth: 800,
            onRenderHeader: p => <div className="filterable-column column-header-title">{p.column.name}</div> 
        });
        cols.push({
            key: "status",
            name:"Status",
            onRenderHeader: p => <div className="filterable-column column-header-title">{p.column.name}</div> 
        });
        cols.push({
            key: 'mtype',
            name: "Type",
            onRenderHeader: p => <div className="filterable-column column-header-title">{p.column.name}</div> 
        });
        cols.push({
            key:"topics",
            name:"Topics",
            minWidth: 200, 
            maxWidth: 300,
            onRenderHeader: p => <div className="filterable-column column-header-title">{p.column.name}</div> 
        })
        cols.push({
            key: "analyze",
            name: "Analyze",
            onRenderHeader: p => <div className="filterable-column column-header-title">{p.column.name}</div> 
        })
        cols.push({
            key:"actions",
            name:"Actions",
            onRenderHeader: p => <div className="filterable-column column-header-title">{p.column.name}</div> 
        })
        
        setColumns(cols);
        setItems(itms);

    }, [props.items, dim]);

    const updateItem = (item, type, file, retries) => {
        if(retries && retries > 5)
            return ;
        
        let md = {
            profile: props.account,
            id: item.id,
            type: type
        }

        let fd = new FormData();
        fd.append("file", file);
        fd.append("metadata", JSON.stringify(md));

        //sendy stuff
        //Submit
        axios
            .post('/api/resubmit', fd, { 
                    headers : {
                                "Authorization":"Bearer " + user.current.token.id,
                                "Coda" :  user.current.scholarsift.coda
                    },
            })
            .then(results => {
                console.log("Submitted " + file.name)
                //Update Items
            })
            .catch(error =>  { APIErrorHandler(error, user, saveUser, () => { updateItem(item, type, file, retries ? retries + 1 : 1) }) });
    }

    const updateItemField = (id, key, value, retries) => {
        if(retries && retries > 5)
            return ;
        
        let d = {
            profile: props.account,
            id: id,
            key: key, 
            value: value
        }

        //Submit
        axios
            .post('/api/usubmission', d, { 
                    headers : {
                                "Authorization":"Bearer " + user.current.token.id,
                                "Coda" :  user.current.scholarsift.coda
                    },
            })
            .then(results => {
                //Update Items
                console.log("Updated: ")
                console.log(d)
            })
            .catch(error =>  { APIErrorHandler(error, user, saveUser, () => { updateItemField(id, key, value, retries ? retries + 1 : 1) }) });
    }

    const doRevision = (item, type) => {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = '.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
        input.onchange = e => {
            updateItem(item, type, e.target.files[0]);
        }
        input.click();
    }

    const handleAnalyze = (item) => {
        navigate("/analyze", {state:{submission: {id: item.id, title: item.title}}});
    }

    const mChange = (_ev, option, _index, value, item, column) => {
        let v;
        if(!option) {
            if(value) {
                setMops([...mOps, {key: value, text:value}]);
                v = value;
            }
        }
        else
            v = option.key;
        let itms = [...items];
        itms.forEach(vv => {
            if(vv.id === item.id)
                vv[column.key] = v;   
        })
        setItems(itms);
        updateItemField(item.id, column.key, v);
    }

    const onRenderItemColumn = (item, index, column) => {
        switch(column.key) {
            case 'title':
                return <SubmittedItem item={item} user={user.current} {...props} doRevision={doRevision} updateItemField={updateItemField}/>;
            case 'status':
                return  <Status id={item.id} status={item.status} lawreviews={props.lawreviews} update={props.update} />
            case 'mtype':
                return <ComboBox 
                            options={mOps}
                            onChange={(_ev, option, _index, value) => { mChange(_ev, option, _index, value, item, column) } }
                            autoComplete="on"
                            allowFreeform={true}
                            selectedKey={item[column.key]}
                            required
                        />
            case 'actions':
                return <Actions {...props} revise={() => {doRevision(item, "file");}} item={item}/>
            case 'analyze': 
                return <PrimaryButton text="Analyze" onClick={() =>handleAnalyze(item)} />// user.current.scholarsift.paid ?  : <TooltipHost content="Available with a susbcription"><PrimaryButton disabled text="Analyze" onClick={() =>handleAnalyze(item)} /></TooltipHost>
            case 'topics':
                return <div key={uuid()}>{item.topics ? item.topics.split('\n').map(v => <div key={uuid()}><TooltipHost content={v}>{v}</TooltipHost></div>) : undefined}</div>
            default: 
               return <div>{item[column.key] ? item[column.key].toString() : ""}</div>;
        }
    }    

    const onFilterChanged = (_, text) => {
        if(text.length === 0)
        {
            setItems([...props.items]);
            return;
        }    

        let txt = text.toLowerCase();
        setItems(props.items.filter(v => v.title.toLowerCase().indexOf(txt) > -1));
    }

    const onColumnClick = (ev, column) => {
        let curr = column;
        let cols = [...columns];

        cols.forEach(c => {
            if(c.key === column.key) {
                c.isSortedDescending = !c.isSortedDescending;
                c.isSorted = true;
                curr = c;
            }
            else {
                c.isSorted = false;
                c.isSortedDescending = true;
            }
        });

        setItems(items.slice(0).sort((a, b) => ((curr.isSortedDescending ? a[curr.fieldName] < b[curr.fieldName] : a[curr.fieldName] > b[curr.fieldName]) ? 1 : -1)));
        setColumns(cols)

    }

    return  <ScrollingFilterableList items={items} resultCountText={items.length !== props.items.length ? items.length + " of " + props.items.length : undefined} columns={columns} onColumnClick={onColumnClick} onRenderItemColumn={onRenderItemColumn} onFilterChanged={onFilterChanged} />
}

export default SubmissionList;
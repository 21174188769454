import { useEffect, useRef, useState } from 'react';
import { 
    DefaultButton, 
    DetailsList, 
    DetailsRow, 
    Dialog,
    DialogFooter,
    DialogType,
    Icon,
    Panel,  
    PanelType,  
    PrimaryButton, 
    Selection,
    SelectionMode, 
    TextField, 
    TooltipHost 
} from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import { v4 as uuid} from "uuid";
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import axios from 'axios';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import { DeleteTask } from '../../../actions/Portal';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ScrollingFilterableList from '../../ScrollingFilterableList';
import { Buffer } from 'buffer'
import { sentenceCase } from 'ss-lib';


const SortByColumn = (itms, column) => {
    itms.sort((a, b) => {
        switch (column.key) {
            case 'above':
            case 'below':
                {
                    if (!a.stats || !a.stats.length)
                        return 1;
                    if (!b.stats || !b.stats.length)
                        return -1;
                    let av = JSON.parse(a.stats)[column.key];
                    let bv = JSON.parse(b.stats)[column.key];
                    if (av === undefined)
                        return 1;
                    if (bv === undefined)
                        return -1;
                    return column.isSortedDescending ? bv - av : av - bv;
                }
            case 'total':
                {
                    if (!a.stats || !a.stats.length)
                        return 1;
                    if (!b.stats || !b.stats.length)
                        return -1;
                    let ast = JSON.parse(a.stats);
                    let av = (ast['above'] ? ast['above'] : 0) + (ast['below'] ? ast['below'] : 0);
                    let bst = JSON.parse(b.stats);
                    let bv = (bst['above'] ? bst['above'] : 0) + (bst['below'] ? bst['below'] : 0);
                    return column.isSortedDescending ? bv - av : av - bv;
                }
            case 'rage':
            case 'rtext':
                {
                    if (!a.stats || !a.stats.length)
                        return 1;
                    if (!b.stats || !b.stats.length)
                        return -1;
                    let av = JSON.parse(a.stats).related;
                    let bv = JSON.parse(b.stats).related;
                    if (av === undefined)
                        return 1;
                    if (bv === undefined)
                        return -1;
                    let k = column.key.substring(1);
                    return column.isSortedDescending ? bv[k] - av[k] : av[k] - bv[k];
                }
            default:
                if (!a[column.key])
                    return 1;
                if (!b[column.key])
                    return -1;
                if (a[column.key] === b[column.key])
                    return 0;
                return column.isSortedDescending ? (a[column.key] > b[column.key] ? 1 : -1) : (a[column.key] > b[column.key] ? -1 : 1)
        }
    })
}

const SubmissionPortalList = props => {
 
    const location = useLocation();
    const { state, dispatch } = useLocationState();
    const { user, saveUser } = useUser();
    const  navigate = useNavigate();

    const [abstract, setAbstract] = useState(undefined);
    const [showAbstract, setShowAbstract] = useState(false);
    const [nOpen, setNOpen] = useState(false);
    const [notesItem, setNotesItem] = useState();
    const [notesState, setNotesState] = useState();
    const [notesContentState, setNotesContentState] = useState();
    const [notesSelectionState, setNotesSelectionState] = useState();
    
    const [filterText, setFitlerText] = useState();

    const [tOpen, setTOpen] = useState(false);
    const [newTag, setNewTag] = useState();
    const [tags, setTags] = useState(props.tags ? props.tags : []);
    const [tagsItem, setTagsItem] = useState();
    const tagsItemRef = useRef();
    const [tagSelections] = useState(new Selection({
        onSelectionChanged: () => {
            if(tagsItemRef.current) {
                if(props.selectedRef.current.length)
                    tagSelections.getItems().forEach((vv, i) => tagSelections.setIndexSelected(i, props.selectedRef.current.indexOf(vv.name) > -1))
                
                if(tagsItem && tagsItem.tags) {
                    if(tagSelections.getItems().length === 0 && props.tags && props.tags.length)
                        tagSelections.setItems(props.tags)
                    
                }

                let ct = 0;
                let sel = tagSelections.getSelection().map(v => v.name);
                let length = 0;
                if(tagsItemRef.current.tags) {
                    tagsItemRef.current.tags.forEach((v, i) => {
                        if(v === sel[i])
                            ct++;
                    })
                    length = tagsItemRef.current.tags.length;
                }
                if(sel.length !== length || ct !== length)
                {
                    let itms = [...itemsRef.current];
                    itms.forEach(v => {
                        if(v.id === tagsItemRef.current.id)
                            v.tags = sel;
                    })
                    setItems(itms);
                }
                let sids = tagSelections.getSelection().map(v => v.id);
                props.processTags(sids, tagsItemRef.current, true);
            }
        }
    }));
    
    const dItem = useRef();
    const [hideDialog, setHideDialog] = useState(true);
    const [items, setItems] = useState(props.items ? props.items : []);
    const [columns, setColumns] = useState(props.columns ? props.columns : []);
    const itemsRef = useRef();
   
    useEffect(() => {
        setColumns(props.columns ? props.columns : []);
    }, [props.columns])

    useEffect(() => {
        //Filter Via Text
        let itms = filterText ? props.items.filter(v => {
            let t = JSON.stringify(v).toLowerCase();
            return t.indexOf(filterText) > -1;
        }) : [...props.items];

        //Fitler Via Ranges
        if(props.columns) {
            props.columns.forEach(column => {
                if(column.filter && column.type === "Rangeable") {
                    let smin = column.filter.sMin;
                    let smax = column.filter.sMax;
                    itms = itms.filter(v => {
                        if (!v.stats || !v.stats.length)
                            return false;
                        
                        let stats = JSON.parse(v.stats);
                        switch (column.key) {
                            case 'above':
                            case 'below':
                                let val = stats[column.key];
                                return val >= smin && val <= smax;
                            case 'total':
                                let v = (stats['above'] ? stats['above'] : 0) + (stats['below'] ? stats['below'] : 0);
                                return v >= smin && v <= smax;
                            case 'rage':
                            case 'rtext':
                                let related = { stats };
                                let k = column.key.substring(1);
                                return related[k].length >= smin && related[k].length <= smax;
                            default:
                                return false;
                        }
                    })
                }
            });

            let column;
            props.columns.forEach(c => {
                if(c.isSorted)
                    column = c;
            });

            if(column)
                SortByColumn(itms, column)
        }
            

        setItems(itms);

        if(props.items) {
            if(notesItem) {
                let itmz = itms.filter(v => notesItem.id === v.id);
                if(itmz.length)
                    setNotesItem(itmz[0]);
            }
            if(tagsItem) {
                let itmz = itms.filter(v => tagsItem.id === v.id);
                if(itmz.length)
                    setTagsItem(itmz[0]);
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items])

    useEffect(() => {
        setTags(props.tags ? props.tags : []);
    }, [props.tags])
    
    useEffect(() => {
        if(notesItem)
        {
            let njs = {};
            if (typeof notesItem.notes === 'string' || notesItem.notes instanceof String)
                {
                    if(notesItem.notes.length)
                        njs = JSON.parse(notesItem.notes);
                }
                else if(
                    typeof notesItem.notes === 'object' &&
                    !Array.isArray(notesItem.notes) &&
                    notesItem.notes !== null
                )
                    njs = notesItem.notes;
            setNotesState(Object.keys(njs).length > 0 ? EditorState.createWithContent(convertFromRaw(njs)) : EditorState.createEmpty());
        }    
    }, [notesItem])

    useEffect(()=> {
        itemsRef.current = items;
    }, [items])

    useEffect(() => {
        if(state[location.pathname].notesUpdate)
        {
            let itms = [...items];
            itms.forEach(v => {
                if(v.id === state[location.pathname].notesUpdate.id) {
                    v.notes = state[location.pathname].notesUpdate.notes;
                    if(tagsItem)
                        setNotesState(EditorState.createWithContent(convertFromRaw(v.notes)))
                    else
                    {
                        setItems(itms);
                        if(nOpen)
                            setNotesState(EditorState.createWithContent(convertFromRaw(v.notes)))
                    }
                }    
            })
            setPKS("notesUpdate", undefined)
        }    
        if(state[location.pathname].tagsUpdate)
        {
            props.setTags([...state[location.pathname].tagsUpdate]);
            setTags([...state[location.pathname].tagsUpdate]);
            setPKS("tagsUpdate", undefined)
        }    
        if(state[location.pathname].tagSelectionUpdate)
        {
            let itms = [...items];
            itms.forEach(v => {
                if(v.id === state[location.pathname].tagSelectionUpdate.id) {
                    v.tags = tags.filter(v => state[location.pathname].tagSelectionUpdate.selected.indexOf(v.id) > -1).map(v => v.name)
                    if(tagsItem)
                        tagSelections.getItems().forEach((vv, i) => tagSelections.setIndexSelected(i, v.tags.indexOf(vv.name) > -1))
                    else
                        setItems(itms);
                }
            })
            setPKS("tagSelectionUpdate", undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, state]);

    useEffect(() => {
        if(tagsItem && tagsItem.tags) {
            if(tagSelections.getItems().length === 0 && props.tags && props.tags.length)
                tagSelections.setItems(props.tags)
            tagSelections.getItems().forEach((v, i) =>  tagSelections.setIndexSelected(i, tagsItem.tags.indexOf(v.name ? v.name : v) > -1))
        }
        tagsItemRef.current = tagsItem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsItem])

    const setPKS = (key, payload) => {
        dispatch({type:'path-key-update', path: location.pathname, key: key, payload: payload})
    }

    const sortByCitation = (type, isSortedDescending) => {
        let itms = [...items].sort((a, b) => { 
            let av = a.stats && a.stats.length ? JSON.parse(a.stats).types : undefined;
            let bv = b.stats && b.stats.length ? JSON.parse(b.stats).types : undefined;
            if(!av)
                return 1;
            if(!bv)
                return -1;
            return ((isSortedDescending ? av[type] < bv[type] : av[type] > bv[type]) ? 1 : -1);
        });
        setItems(itms);
    }

    const DoDeleteTask = () => {
        if(dItem.current === undefined)
            return ;
        let item = dItem.current;
        let d = {profile: user.current.scholarsift.profile, id: item.id, editors: props.getEditors(item.editors), deleteTask: true}
        DeleteTask(d, user, saveUser, v => {
            props.processWS({key: "portal/deltask", data: d});
            props.FetchSubmissions();
        })
    }

    const getAbstract = id => {
        axios
            .post("/api/abssum", {id: id}, {
                headers : {
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" :  user.current.scholarsift.coda
                }
            })
            .then(results => {
                setAbstract(results.data.split("\n"))
                setShowAbstract(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onRenderItemColumn = (item, index, column) => {
        console.log(item)
        switch(column.key) {
            case 'abstract':
                if(item[column.key] && item[column.key].indexOf("summary") > -1)
                    return <Button appearance='primary' shape='rounded' onClick={()=> {getAbstract(item.id)}}>Read</Button>
            case 'cover':
            case 'cv':
                return item[column.key] && item[column.key].length > 0 ? <div key={uuid()}><a href={"/api/submission/" + Buffer.from(item.id + "|" + user.current.scholarsift.profile + "|" + column.key + "|" + user.current.scholarsift.email).toString("base64")} aria-label={"download " + column.key} target="_blank" rel="noopener noreferrer"><Icon className="list-item-icon" iconName="Download" /></a></div> : null;
            case 'above':
            case 'below':
                if(!item.stats || !item.stats.length)
                    return null;
                return <div key={uuid()} >{JSON.parse(item.stats)[column.key]}</div>
            case 'total':
                {
                    if(!item.stats || !item.stats.length)
                        return null;
                    let st = JSON.parse(item.stats);
                    let v = (st['above'] ? st['above'] : 0) + (st['below'] ? st['below'] : 0);
                    return v ? <div key={uuid()}>{v}</div> : null;
                }
            case 'assignments':
                return  location.pathname.indexOf("todo") === -1 ? 
                        <div key={uuid()} className="removable-link">
                            <div className="link-div" onClick={() => {props.processAssignments(item)}}>{item.tasks && item.tasks.length > 0 ? item.tasks.map(v => <div key={uuid()}>{v}</div>) : "Assign"}</div>
                            {item.tasks && item.tasks.length > 0 && <div className="link-div" key={uuid()}><Icon iconName="Cancel" onClick={() => { dItem.current = item; setHideDialog(false); }}/></div> }
                        </div> :
                        <div key={uuid()}>{item.task}</div>
            case 'authors':
            {
                if(!item[column.key] || !item[column.key].length)
                    return null;
                let s = new Set();
                JSON.parse(item[column.key]).forEach(v => {
                    s.add(v.name);
                });
                let r = [];
                [...s].sort().forEach(v => { r.push(<div key={uuid()}>{v}</div>)})
                return <div key={uuid()}>{r}</div>
            }
            case 'citations':
            {
                if(!item.stats || !item.stats.length)
                    return null;
                let j = JSON.parse(item.stats).types;
                if(!j)
                    return null;
                let r = [];
                Object.keys(j).sort().forEach(v => { 
                    r.push(<div className="sortable" key={uuid()} onClick={() => {
                        let c = undefined;
                        let cols = [...columns];
                        cols.forEach(vv => {
                            if(vv.key === "citations")
                            {
                                c = vv;
                                c.isSorted = true;
                            }
                            else
                            {
                                vv.isSortedDescending = true;
                                vv.isSorted = false;
                            }
                        })
                        let key = v + "_desc";
                        c[key] = !c[key];
                        c.isSortedDescending = c[key];                
                        sortByCitation(v, c[key]);
                    }}>{v + " " + j[v]}</div>)})
                return <div key={uuid()} >{r}</div>;
            }
            case 'gender':
            {
                if(item.demographics) {
                    let s = new Set();
                    item.demographics.forEach(v => {
                        s.add(v.genders);
                    })

                    let r = [];
                    [...s].sort().forEach(v => {
                        r.push(<div key={uuid()}>{v}</div>);
                    })
                    return <div key={uuid()} >{r}</div>
                }
                return null;
            }
            case 'rage':
            case 'rtext':
            {
                if(!item.stats || !item.stats.length)
                    return null;
                let related = JSON.parse(item.stats).related;
                let key = column.key === "rage" ? "age" : "text";
                return <div key={uuid()} className="link-div" onClick={() => {props.setHeaderText("Related " + (key === "age" ? "Recent " : "") + "Articles"); props.processRelated(related[key]) }}>{related && related[key] ? related[key].length : null}</div>
            }
            case 'notes':
                let njs = {};
                if (typeof item[column.key] === 'string' || item[column.key] instanceof String)
                {
                    if(item[column.key].length)
                        njs = JSON.parse(item[column.key]);
                }
                else if(
                    typeof item[column.key] === 'object' &&
                    !Array.isArray(item[column.key]) &&
                    item[column.key] !== null
                )
                    njs = item[column.key];
                
                let content = "";
                if(Object.hasOwn(njs, "preemption"))
                {
                    var z = {
                        data:   {
                                    targetOption: "_blank",
                                    url: "https://" + window.location.hostname + "/api/fetch/" + njs["preemption"]
                                },
                        mutability: "MUTABLE",
                        type: "LINK"
                    };
                        
                    var em = {};
                    em["0"] = z;
                    
                    var blocks = [
                        {
                            depth: 0,
                            data:{},
                            inlineStyleRanges:[],
                            text: "Possible Preemption: Here",
                            type: "unstyled",
                            key: uuid().substring(30),
                            entityRanges: [{offset: 21, length: 4, key: 0}]
                        }
                    ];
                    
                    var raw = {};
                    raw["entityMap"] = em;
                    raw["blocks"] = blocks;

                    njs = raw;
                    item[column.key] = raw;
                    content = draftToHtml(raw);
                }
                else {
                    if(Object.keys(njs).length)
                        content = draftToHtml(njs);
                }

                return  <div key={uuid()} className="link-div" onClick={() => { props.checkWS(); setNotesItem(item); setNOpen(true) }}>
                            {item[column.key] && njs.blocks && njs.blocks[0].text > 0 ? <TooltipHost content={<div key={uuid()} dangerouslySetInnerHTML={{ __html: content }}></div>}>{njs.blocks ? njs.blocks[0].text : ""}</TooltipHost> : "Add Notes" }
                        </div>
            case 'atitle':
            {
                if(item.demographics) {
                    let d = [];
                    item.demographics.forEach(v => {
                        d[v.name] = v.title
                    })

                    item.atitle = "";
                    let r = Object.keys(d).sort().map(v => {
                        item.atitle += d[v] + "\n";
                        return <div key={uuid()}><TooltipHost content={d[v]}>{d[v]}</TooltipHost></div>;
                    })
                    return <div key={uuid()}>{r}</div>
                }
                return null;
            }
            case 'role':
            {
                if(item.demographics) {
                    let d = {}
                    item.demographics.forEach(v => {
                        d[v.name] = v.roles
                    })

                    item.role = "";
                    let r = Object.keys(d).sort().map(v => {
                        item.role += d[v] + "\n";
                        return <div key={uuid()}><TooltipHost content={d[v]}>{d[v]}</TooltipHost></div>;
                    })
                    return <div key={uuid()}>{r}</div>
                }
                return null;
            }
            case 'ethnicity':
            {
                return  item.demographics && item.ethnicity ? 
                        <div key={uuid()}>{item.ethnicity.split('\n').filter(v => v.length).sort().map(v => <div key={uuid()}><TooltipHost content={v}>{v}</TooltipHost></div>)}</div>:
                        null;
            }
            case 'topics':
                return <div key={uuid()}>{item.topics.split('\n').map(v => <div key={uuid()}><TooltipHost content={sentenceCase(v)}>{sentenceCase(v)}</TooltipHost></div>)}</div>
            case 'tags':
                return  <div key={uuid()} className="link-div" onClick={() => { setTagsItem(item); setNewTag(undefined); setTOpen(true) }}>
                            {item.tags && item.tags.length ? <TooltipHost content={item.tags.map(v => <div key={uuid()}>{v.name ? v.name : v}</div>)}>{item.tags.map(v => <div key={uuid()}>{v.name ? v.name : v}</div>)}</TooltipHost> : "Add Tags" }
                        </div>
            case 'title':
                return <div key={uuid()} className="flex-column">
                            <TooltipHost content={item[column.key] ? item[column.key].toString() : ""}>
                                <a href={"/api/submission/" + Buffer.from(item.id + "|" + user.current.scholarsift.profile + "|manuscript|" + user.current.scholarsift.email, 'ascii').toString("base64")} aria-label="download file" target="_blank" rel="noopener noreferrer">{item[column.key] ? item[column.key].toString() : ""}</a>
                            </TooltipHost>
                        </div>;
            case 'status':
                return item[column.key] === -1 ? 
                        <div key={uuid()}>Previously Published</div> : 
                        <div key={uuid()} className="link-div" onClick={() => {props.processStatus(item)}}>
                            {location.pathname.indexOf("todo") === -1 ? item.msg : item.status}
                        </div>                        
            case 'analyze':  return <div className="centered"> <PrimaryButton text="Analyze" onClick={() => handleAnalyze(item)}/></div>
            case 'orientation':
            {
                if(item.demographics) {
                    let s = new Set();
                    item.demographics.forEach(v => { if(v.orientation) s.add(v.orientation) });
                    let r = [];
                    [...s].forEach(v => {r.push(<div key={uuid()}><TooltipHost content={v}>{v}</TooltipHost></div>)})
                    return <div key={uuid()} >{r}</div>
                }
                return null;
            }    
            default: 
                return <div key={uuid()}><TooltipHost content={item[column.key] ? item[column.key].toString() : ""}>{item[column.key] ? item[column.key].toString() : ""}</TooltipHost></div>;
        }
    }    

    const onFilterChanged = (_, text) => {
        let txt = text.toLowerCase();
        if(txt.length === 0)
        {
            setItems([...props.items])
            return ;
        }    
        let itms = props.items.filter(v => {
            let t = JSON.stringify(v).toLowerCase();
            return t.indexOf(txt) > -1;
        });
        setItems(itms);
        setFitlerText(txt);
    }

    const onColumnClick = (ev, column) => {
        let cols = [...columns];

        cols.forEach(c => {
            if(c.key === column.key) {
                c.isSortedDescending = !c.isSortedDescending;
                c.isSorted = true;
            }
            else {
                c.isSorted = false;
                c.isSortedDescending = true;
            }
        });

        let itms = [...items];
        
        SortByColumn(itms, column);

        setColumns(cols)
        setItems(itms)
    }

    const processNewTag = (retries) => {
        if(retries && retries > 5)
            return ;
        
        //Add to tags (db, ws)
        axios
            .post("/api/addtag", {profile: props.lawReview ? props.lawReview : user.current.scholarsift.profile, item: tagsItem.id, tag: newTag}, {
                headers : {
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" :  user.current.scholarsift.coda
                }
            })
            .then(results => {
                if(results.data.error === undefined) {
                    let keys = Object.keys(results.data);
                    let tag = {name: keys[0], id: results.data[keys[0]].tag}
                    let t = [...tags, tag]
                    setTags(t);
                    props.selectedRef.current = [...tagsItemRef.current.tags];
                    props.processWS({key: "portal/tags", data: {tags: t}});
                }
            })
            .catch(err => { APIErrorHandler(err, user, saveUser, () => { processNewTag(retries ? retries + 1 : 1) })
        })
    }

    const removeTag = (tag, retries) => {
        if(retries && retries > 5)
            return ;
        
        //Add to tags (db, ws)
        axios
            .post("/api/remtag", {profile: props.lawReview ? props.lawReview : user.current.scholarsift.profile, tag: tag.id}, {
                headers : {
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" :  user.current.scholarsift.coda
                }
            })
            .then(results => {
                if(results.data.error === undefined) {
                    let rem = tags.filter(v => v.id !== results.data.id);
                    props.setTags(rem);
                    props.processWS({key: "portal/tags", data: {tags: rem}});
                }
            })
            .catch(err => { APIErrorHandler(err, user, saveUser, () => { processNewTag(retries ? retries + 1 : 1) })
        })
    }

    const closeTags = () => { 
        setTOpen(false);
        let itms = [...itemsRef.current];
        let item;
        itms.forEach(v => {
            if(v.id === tagsItem.id)
                item = v;
        })
        if(item) {
            let sel = tagSelections.getSelection().map(v => v.id);
            let ct = 0;
            if(item.tags) {
                item.tags.forEach((v, i) => {
                if(v === sel[i])
                    ct++;
                })
                if(sel.length !== item.tags.length || ct !== item.tags.length)
                {
                    item.tags = tags.filter(v => sel.indexOf(v.id) > -1).map(v => v.name);
                    setItems(itms)
                }
                props.processTags(sel, tagsItem)
            }
        }
    }

    const handleAnalyze = (item) => {
        navigate("/analyze", {state:{submission:{id: item.id, title: item.title}}});   
    }

    const handleChange = editorState => {
        let cc = editorState.getCurrentContent();
        let cs = editorState.getSelection();

        setNotesState(editorState);
        
        if(notesSelectionState !== cs)
            setNotesSelectionState(cs);
        
        if(notesContentState !== cc)
        {
            setNotesContentState(cc);
            props.handleNotes(convertToRaw(cc), notesItem);
        }
    }

    const renderRow = props => {
        const rowStyles = {
            cell: { fontSize: 22 }
        }
        if (!props) return null 
        return <DetailsRow {...props} styles={rowStyles}/>
    }

    return  <>
                <ScrollingFilterableList DnD={true} omitted={props.omitted} order={props.order} selectColumns={props.selectColumns} resetView={props.resetView} items={items} resultCountText={items.length !== props.items.length ? items.length + " of " + props.items.length : undefined} columns={columns} onColumnClick={onColumnClick} onRenderItemColumn={onRenderItemColumn} onFilterChanged={onFilterChanged} />
                <Panel
                    isLightDismiss
                    isOpen={nOpen}
                    onDismiss={() => { setNOpen(false); props.processNotes(convertToRaw(notesState.getCurrentContent()), notesItem)}}
                    onDismissed={() => { setNOpen(false) }}
                    headerText={"Notes"}
                    closeButtonAriaLabel="Close"
                    type={PanelType.large}
                >
                    <div className="notes-title">{notesItem ? notesItem.title : null}</div>
                    <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        editorState={notesState}
                        onEditorStateChange={handleChange}
                    />
                    <div>* Notes are automatically saved and shared between users</div>
                    <div className="notes-buttons">
                        <PrimaryButton 
                            className="filter-item" 
                            text="Close"
                            onClick={() => { setNOpen(false); props.processNotes(convertToRaw(notesState.getCurrentContent()), notesItem)}}
                        />
                    </div>
                </Panel>
                <Panel
                    isLightDismiss
                    selectionPreservedOnEmptyClick
                    isOpen={tOpen}
                    onDismiss={closeTags}
                    onDismissed={() => { setTOpen(false) }}
                    headerText={(tagsItem ? tagsItem.title : "") + " Tags"}
                    closeButtonAriaLabel="Close"
                >
            <DetailsList
                        selectionZoneProps={{selectionClearedOnSurfaceClick: false, selectionPreservedOnEmptyClick
: true                        }}
                        selectionMode={SelectionMode.multiple}
                        selection={tagSelections}
                        columns={[{name:"Tags", key: "name"}, {name: "Remove Tag", key:"remove"}]}
                        items={tags}
                        onRenderRow={renderRow}
                        onRenderItemColumn={(item, index, column) => {
                        if(column.key === "remove")
                            return <PrimaryButton text="Remove Tag" onClick={(e)=> {e.stopPropagation(); removeTag(item); }} />;
                        return <div>{item[column.key]}</div>
                    }}
                    />
                    <div className="filter-buttons">
                        <TextField placeholder="Add New Tag" value={newTag} onChange={(_, newValue) => { setNewTag(newValue) }} onKeyUp={ev => { if(ev.key === 'Enter') processNewTag(); }} />
                        <PrimaryButton 
                                className="filter-item" 
                                text="Add"
                                onClick={processNewTag}
                            />
                    </div>
                    <div className="filter-buttons">
                        <PrimaryButton 
                            className="filter-item" 
                            text="Close"
                            onClick={closeTags}
                        />
                        <PrimaryButton
                            className="filter-item"
                            text="Cancel"
                            onClick={() => {setTOpen(false)}}
                        />
                    </div>
                </Panel>
                <Panel
                    isOpen={showAbstract}
                >
                    <h1>Abstract</h1>
                    {abstract && abstract.map(v => <p key={uuid()}>{v}</p>)}
                    <div className="notes-buttons">
                        <PrimaryButton 
                            className="filter-item" 
                            text="Close"
                            onClick={() => { setShowAbstract(false); }}
                        />
                    </div>
                </Panel>
                <Dialog
                    hidden={hideDialog}
                    onDismiss={() => {setHideDialog(true)}}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete Assignment',
                        subText: 'Are you sure you want to delete this Assignment?'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <PrimaryButton onClick={() => { setHideDialog(true); DoDeleteTask();}} text="Yes" />
                        <DefaultButton onClick={() => { setHideDialog(true) }} text="No" />
                    </DialogFooter>
                </Dialog>
            </>
}

export default SubmissionPortalList;